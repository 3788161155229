<template>
  <div>
    <b-card no-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(pot)="data">
          {{ data.item.pot }}
        </template>
        <template #cell(tvlp1)="data">
          {{ data.item.tvlp1 }}
        </template>
        <template #cell(tvlps)="data">
          {{ data.item.tvlps }}
        </template>
        <template #cell(tvls1)="data">
          {{ data.item.tvls1 }}
        </template>
        <template #cell(tvlss)="data">
          {{ data.item.tvlss }}
        </template>
        <template #cell(es015)="data">
          {{ data.item.es015 }}
        </template>
        <template #cell(es030)="data">
          {{ data.item.es030 }}
        </template>
        <template #cell(es045)="data">
          {{ data.item.es045 }}
        </template>
        <template #cell(es060)="data">
          {{ data.item.es060 }}
        </template>
        <template #cell(es090)="data">
          {{ data.item.es090 }}
        </template>
        <template #cell(es135)="data">
          {{ data.item.es135 }}
        </template>
        <template #cell(tvlnt)="data">
          {{ data.item.tvlnt }}
        </template>
        <template #cell(hvlss)="data">
          {{ data.item.hvlss }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'

export default {
  components: { BCard, BTable },
  data() { return { items: [] } },
  computed: {
    readItems() { return this.items },
    fields() {
      return [
        { key: 'pot', label: this.$t('reducingDeciLayers.table.pot'), sortable: true },
        { key: 'tvlp1', label: this.$t('reducingDeciLayers.table.tvlp1'), sortable: true },
        { key: 'tvlps', label: this.$t('reducingDeciLayers.table.tvlps'), sortable: true },
        { key: 'tvls1', label: this.$t('reducingDeciLayers.table.tvls1'), sortable: true },
        { key: 'tvlss', label: this.$t('reducingDeciLayers.table.tvlss'), sortable: true },
        { key: 'es015', label: this.$t('reducingDeciLayers.table.es015'), sortable: true },
        { key: 'es030', label: this.$t('reducingDeciLayers.table.es030'), sortable: true },
        { key: 'es045', label: this.$t('reducingDeciLayers.table.es045'), sortable: true },
        { key: 'es060', label: this.$t('reducingDeciLayers.table.es060'), sortable: true },
        { key: 'es090', label: this.$t('reducingDeciLayers.table.es090'), sortable: true },
        { key: 'es135', label: this.$t('reducingDeciLayers.table.es135'), sortable: true },
        { key: 'tvlnt', label: this.$t('reducingDeciLayers.table.tvlnt'), sortable: true },
        { key: 'hvlss', label: this.$t('reducingDeciLayers.table.hvlss'), sortable: true },
      ]
    },
  },
  async created() { await this.load() },
  methods: {
    async load() {
      this.items = await this.$store.dispatch('reducingDeciLayer/index')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
